<template>
  <div>
    <h2>SALE INQUIRY</h2>
    <input type="file" id="file" @change="handleFile" />
    <button @click="clearInputFile()">Clear</button>
    <div id="canvas" style="display:none"></div>

    <div class="card mt-2 ">
      <div class="card-body">
        <div class="row">
          <div class="col text-start ">
            <span class="fw-bold">CARD NO. : </span>
            {{ model.cardNumber }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">ID CARD NO. : </span> {{ model.idCardNumber }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start"><span class="fw-bold">ชื่อ : </span> {{ model.name }}</div>
          <div class="col text-start">
            <span class="fw-bold">สกุล : </span> {{ model.lastName }}
          </div>
        </div>
      </div>
    </div>

    <table class="table table-bordered mt-5">
      <thead>
        <tr>
          <th>Page</th>
          <th>
            SALE DATE
          </th>
          <th>REF. NO.</th>
          <th>TYPE</th>
          <th>AMOUNT</th>
          <th>STATUS</th>
          <th>SOURCE</th>
          <th>CREDIT</th>
          <!-- <th>End</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in model.rows" :key="index" :class="`color` + row.pageNumber">
          <td>{{ row.pageNumber }}</td>
          <td>{{ row.saleDate }}</td>
          <td>{{ row.refNumber }}</td>
          <td>{{ row.type }}</td>
          <td>{{ row.amount }}</td>
          <td>{{ row.status }}</td>
          <td>{{ row.source }}</td>
          <td>{{ row.credit }}</td>
          <!-- <td>{{ row.endIndex }}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
tr.color2,
tr.color4,
tr.color6,
tr.color8,
tr.color10,
tr.color12,
tr.color14,
tr.color16,
tr.color18,
tr.color20,
tr.color22 {
  background: rgba(0, 0, 0, 0.05);
}
</style>

<script>
import _ from "lodash";

import BaseConvert from "./convert-pdf-to-image.vue";
import utilsMixins from "../_mixins/utils-mixins";
export default {
  extends: BaseConvert,

  data() {
    return {
      model: {
        cardNumber: "",
        idCardNumber: "",
        name: "",
        lastName: "",
        rows: []
      },
      configProp: {
        saleDate: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        refNumber: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        type: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        amount: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        status: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        source: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        credit: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        }
      },

      currentRow: 0
    };
  },
  methods: {
    clearModel() {
      this.model = {
        cardNumber: "",
        idCardNumber: "",
        name: "",
        lastName: "",
        rows: []
      };

      this.currentRow = 0;
    },
    clearInputFile() {
      document.getElementById("file").value = null;
    },
    async handleFile(e) {
      let file = e.target.files[0];

      if (!file) {
        return;
      }

      this.convertToImagesBase64(file, results => {
        this.clearModel();

        results = _.orderBy(results, "pageNumber");
        let promises = results.map(x => this.callApi(x.result));
        Promise.all(promises).then((reses, index) => {
          reses.forEach((res, index) => {
            let pageNumber = index + 1;
            if (pageNumber == 1) {
              let valid = this.validateTypeFile(res);
              if (!valid) {
                alert("กรุณาเลือกไฟล์ PDF ประเภท SALE INQUIRY เท่านั้น");
                return;
              }
            }
            //remove first order for full text
            res.responses[0].textAnnotations.splice(0, 1);

            if (pageNumber == 1) {
              this.findX(res);
              this.getHeader(res);
            }

            this.getRows(res, pageNumber);
          });
        });
      });
    },
    callApi(content) {
      let url =
        "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCv22vemzG4kY1ujcNMYCD0YKQGgAo3t7E";
      return this.$http.post(
        url,
        {
          requests: [
            {
              image: {
                content: `${content}`
              },
              features: [
                {
                  type: "TEXT_DETECTION"
                }
              ]
            }
          ]
        },
        { disabledBaseUrl: true }
      );
    },
    findX(res) {
      let arr = res.responses[0].textAnnotations;
      let gapXpx = 5;

      const findSaleDate = () => {
        let xx = [];
        arr.forEach((item, index) => {
          try {
            if (
              arr[index].description.toUpperCase().includes("SALE".toUpperCase()) &&
              arr[index + 1].description.toUpperCase().includes("DATE".toUpperCase())
            ) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));
              xx.push(...arr[index + 1].boundingPoly.vertices.map(x => x.x));

              this.configProp.saleDate.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.saleDate.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.saleDate.headerX.beginIndex = index;
              this.configProp.saleDate.headerX.endIndex = index + 1;
              return;
            }
          } catch (e) {}
        });
      };

      const findRefNumber = () => {
        let xx = [];
        arr.forEach((item, index) => {
          try {
            if (
              arr[index].description.toUpperCase().includes("REF".toUpperCase()) &&
              arr[index + 1].description.toUpperCase().includes("NO".toUpperCase())
            ) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));
              xx.push(...arr[index + 1].boundingPoly.vertices.map(x => x.x));

              this.configProp.refNumber.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.refNumber.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.refNumber.headerX.beginIndex = index;
              this.configProp.refNumber.headerX.endIndex = index + 1;
              return;
            }
          } catch (e) {}
        });
      };
      const findType = () => {
        let xx = [];
        arr.slice(this.configProp.refNumber.headerX.endIndex + 1).forEach((item, index) => {
          try {
            if (arr[index].description.toUpperCase().includes("ΤΥΡE".toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.type.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.type.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.type.headerX.beginIndex = index;
              this.configProp.type.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findAmount = () => {
        let xx = [];
        arr.slice(this.configProp.type.headerX.endIndex + 1).forEach((item, index) => {
          try {
            if (arr[index].description.toUpperCase().includes("AMOUNT".toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.amount.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.amount.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.amount.headerX.beginIndex = index;
              this.configProp.amount.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findStatus = () => {
        let xx = [];
        arr.slice(this.configProp.type.headerX.endIndex + 1).forEach((item, index) => {
          try {
            if (arr[index].description.toUpperCase().includes("STATUS".toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.status.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.status.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.status.headerX.beginIndex = index;
              this.configProp.status.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };
      const findSource = () => {
        let xx = [];
        arr.slice(this.configProp.type.headerX.endIndex + 1).forEach((item, index) => {
          try {
            if (arr[index].description.toUpperCase().includes("SOURCE".toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.source.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.source.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.source.headerX.beginIndex = index;
              this.configProp.source.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findCredit = () => {
        let xx = [];
        arr.slice(this.configProp.type.headerX.endIndex + 1).forEach((item, index) => {
          try {
            if (arr[index].description.toUpperCase().includes("CREDIT".toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.credit.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.credit.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.credit.headerX.beginIndex = index;
              this.configProp.credit.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      findSaleDate();
      findRefNumber();
      findType();
      findAmount();
      findStatus();
      findSource();
      findCredit();
    },
    validateTypeFile(res) {
      return res.responses[0].fullTextAnnotation.text.includes("SALE INQUIRY");
    },
    getHeader(res) {
      this.hCardNo(res.responses[0].textAnnotations);
      this.hIdCardNo(res.responses[0].textAnnotations);
      this.hName(res.responses[0].textAnnotations);
    },
    hCardNo(arr) {
      let index = arr.findIndex(x => x.description === "INQUIRY");
      this.model.cardNumber = arr[index + 3].description;
    },
    hIdCardNo(arr) {
      let index = arr.findIndex(x => x.description === "INQUIRY");
      this.model.idCardNumber = arr[index + 8].description;
    },
    hName(arr) {
      let index = arr.findIndex(x => x.description === "INQUIRY");
      this.model.name = arr[index + 11].description;
      this.model.lastName = arr[index + 12].description;
    },
    getRows(res, pageNumber) {
      let gapYpx = 3;

      res.responses[0].textAnnotations
        .slice(this.configProp.credit.headerX.endIndex + 1)
        .forEach((item, index) => {
          let xx = item.boundingPoly.vertices.map(x => x.x);
          let start = Math.min(...xx);
          let end = Math.max(...xx);
          let positionUse = (start + end) / 2;

          let ymin = item.boundingPoly.vertices[0].y;

          if (
            this.configProp.saleDate.headerX.min <= positionUse &&
            this.configProp.saleDate.headerX.max >= positionUse
          ) {
            let row = {};
            row.pageNumber = pageNumber;
            for (let prop in this.configProp) {
              row[prop] = "";
            }

            let yy = [];
            yy.push(...item.boundingPoly.vertices.map(x => x.y));
            row.ymin = Math.min(...yy) - gapYpx;
            row.ymax = Math.max(...yy) + gapYpx;
            row.beginIndex = index;
            row.saleDate = item.description;

            this.model.rows.push(row);
            this.currentRow++;
          } else {
            for (let prop in this.configProp) {
              if (prop != "saleDate") {
                if (
                  this.configProp[prop].headerX.min <= positionUse &&
                  this.configProp[prop].headerX.max >= positionUse
                ) {
                  let row = this.model.rows.filter(
                    x => x.ymin <= ymin && x.ymax >= ymin && x.pageNumber == pageNumber
                  )[0];
                  if (row) {
                    // row[prop] = `${row[prop]} ${item.description}`;
                    row[prop] += ` ${item.description}`;
                  }
                }
              }
            }
          }
        });

      let regexDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

      _.remove(this.model.rows, row => {
        return !regexDate.test(row.saleDate);
      });
    }
  }
};
</script>
